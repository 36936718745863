aside a {
  padding: 19px;
  display: flex;
  align-items: center;
  width: 100%;
  color: darkslategrey;
  text-decoration: none;
  color: black;
}

aside a:not(last-child) {
  border-bottom: 0.1px solid;

}

aside {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  z-index: 123899988276976 !important;
  transition: 0.7s ease-in-out;
}

.arabicContent aside {
  right: -100%;
  left: none;
}

.arabicContent aside.active {
  right: 0;
  left: none;
}

.EnglishContent aside {
  right: none;
  left: -100%;
}

.EnglishContent aside.active {
  right: none;
  left: 0;
}

.container_co {
  width: min(300px, 100%);
  display: flex;
  flex-direction: column;
  gap: 2px;
  background: white;
  /* justify-content: space-between; */
  height: 100%;
  z-index: 9;
}

.container-coo {
  padding: 19px 10px;
  overflow-y: auto;

}

.dark_b {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000082;
}

.toggle_side {
  width: 70px;
  height: 70px;
  cursor: pointer;
  border: 1px solid;
  padding: 5px;
}

span#toggle_side {
  display: flex;
  cursor: pointer;
  border: 1px solid;
  padding: 5px;
  font-size: 23px;
  border-radius: 5px;
}

.logo_we {
  height: 200px;
  background: var(--main-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  padding: 50px 0 !important;
}

.logo_we img {
  width: 200px;
  height: 200px;
}

a:hover {
  color: var(--main-color) !important;
}