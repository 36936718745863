footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 3px solid var(--main-color);
  min-height: 50px;
}

footer > * {
  margin: auto;
  display: flex;
  gap: 15px;
}

footer .socialLinks{
  font-size: 22px;
}

footer a {
  color: #575757;
  display: flex;
  white-space: nowrap;
}