@font-face {
  font-family: "DinNext";
  src: url("./assests/fonts/din-next-lt-w23-regular.ttf");
}

* {
  font-family: "DinNext";
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
}

.childs {
  margin: 10px 0;
  min-height: 76vh;
  margin-top: 71px !important;
}

a {
  text-decoration: none !important;
}

form button {
  margin-top: 200px !important;
}

form {
  width: min(400px, 100%) !important;
}

/* .childs > div > div > div {
  padding: 11px;
} */

#root
  > div.siteContent.arabicContent
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.product_image {
  width: 70% !important;
}

ol,
ul {
  padding-right: 1rem;
}

.comporder {
  position: fixed;
  bottom: -10px;
  background-color: rgba(0, 0, 0, 0.634);
  color: white;
  width: 90%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  font-family: DIN Next LT W23;
}

.city_div > div {
  width: 100% !important;
}

.filters p {
  padding: 20px;
  font-weight: 900;
  font-size: 20px;
  color: var(--main-color);
  width: fit-content;
  margin: auto;
  margin-bottom: 0;
  padding-bottom: 0;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error.Toastify__toast--close-on-click {
  background-color: #f9eded !important;
  border: 1px solid #e2c5c7 !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success.Toastify__toast--close-on-click {
  background-color: #e8f9f1 !important;
  border: 1px solid #bbd5c7 !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--warning.Toastify__toast--close-on-click {
  background-color: #fff9e1 !important;
  border: 1px solid #f1eace !important;
}

.arabicContent + .Toastify__toast,
.arabicContent
  + .Toastify
  .Toastify__toast.Toastify__toast-theme--light.Toastify__toast--close-on-click {
  direction: rtl !important;
  text-align: right !important;
}

.branch > h5 {
  text-align: left !important;
}
.arabicContent .branch > h5 {
  text-align: right !important;
}
button.Toastify__close-button.Toastify__close-button--light {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.Toastify__toast--error
  button.Toastify__close-button.Toastify__close-button--light
  svg {
  fill: #dac3c3 !important;
}

.Toastify__toast--success
  button.Toastify__close-button.Toastify__close-button--light
  svg {
  fill: #bcc9c3 !important;
}

.Toastify__toast--warning
  button.Toastify__close-button.Toastify__close-button--light
  svg {
  fill: #e8deb7 !important;
}

button.Toastify__close-button.Toastify__close-button--light svg {
  width: 25px !important;
  height: 25px !important;
  font-size: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light {
  opacity: 0 !important;
}

.Toastify__toast--success .Toastify__toast-body > div:last-child {
  color: green;
}

.Toastify__toast--error .Toastify__toast-body > div:last-child {
  color: red;
}

.Toastify__toast--warning .Toastify__toast-body > div:last-child {
  color: #f7d03e;
}

.ar label,
.ar input,
.ar select {
  text-align: right !important;
}

.ar .add_location_form form > div {
  text-align: right;
}
