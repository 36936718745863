.dowloadApp > img {
  width: 100%;
}

.dowloadApp {
  cursor: pointer;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #c5c6c778;
  border-radius: 7px;
}