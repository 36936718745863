.auth {
  min-height: 300px;
}

form {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 300px;
  margin: auto;
}

.inputField label {
  display: block;
  margin: 10px 0;
}

.inputField input {
  padding: 10px;
}

form label,
form .rs-input,
.inputField {
  width: 100% !important;
}

button.btn.auth-btn {
  width: 100%;
  padding: 10px;
  margin-top: 33px;
  background: var(--main-color);
  color: white;
}

form .rs-input-group {
  width: 100% !important;
}