.waytogetorder {
  width: 500px;
  margin: 30px auto;
  max-width: 100%;
  text-align: center;
}

.waytogetorder .first_way,
.waytogetorder .second_way {
  border-radius: 8px;
  padding: 30px 20px;
  background: #f6f6f6;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.firt_way_title,
.second_way_title {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  /* justify-content: space-between; */
}

.firt_way_title div,
.second_way_title div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.bigchecktwo,
.bigcheckone {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid var(--main-color);
  cursor: pointer;
}

.bigcheckone.active {
  background-color: var(--main-color);
}

.bigchecktwo.active {
  background-color: var(--main-color);
}

.first_way_ways {
  text-align: center;
}

.add_new_loc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
}

.add_new_loc button {
  background-color: var(--main-color);
  color: white;
  padding: 9px 10px;
}


/* hir */

/* .first_way_ways>div {
  display: flex;
  margin-bottom: 20px;
  border: 1px dashed var(--main-color);
  padding: 20px;
  justify-content: space-between;
} */

.first_way_ways>div .first_way_ways_left h4,
.first_way_ways>div .second_way_ways_left h4 {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: right;
  color: #a0a0a0;
}

.first_way_ways>div .first_way_ways_right,
.first_way_ways>div .second_way_ways_right {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--main-color);
  cursor: pointer;
}

.first_way_ways>div .first_way_ways_left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.first_way_ways>button,
.confbtntime {
  background-color: var(--main-color);
  color: white;
  width: 200px;
  max-width: 100%;
  padding: 16px;
  margin: 10px auto 30px;
  border-radius: 8px;
}

.first_way_ways_right.active {
  background-color: var(--main-color);
}

.second_way_ways {
  text-align: center;
  justify-content: center;
}

.second_way_ways>label {
  display: flex;
  align-items: center;
  gap: 15px;
  color: white;
  padding: 15px;
  width: 300px;
  max-width: 100%;
  flex-wrap: wrap;
  background-color: var(--main-color);
  margin: 40px auto;
  border-radius: 4px;
  cursor: pointer;
}

.rs-stack {
  display: block !important;
}

.date_div {
  position: absolute !important;
  transform: translateY(-100%) !important;
}

/* .rs-picker-menu {
  right: 50%;
  bottom: 0px !important;
  height: fit-content;
  display: block !important;
  transform: translateX(50%) !important;
} */

/* .rs-stack-item {
  bottom: 0px !important;
} */

.alltimediv {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fbcebd;
  padding: 20px;
  border-radius: 8px;
  color: var(--main-color);
}

.alltimediv>div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.alltimediv>div h4 {
  font-size: 12px;
  font-weight: normal;
  line-height: 40px;
  font-style: normal;
  text-align: left;
  color: var(--main-color);
}

.confirm_btn_order {
  background-color: var(--main-color);
  color: white;
  width: 300px;
  max-width: 100%;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
} 

.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-date-menu.rs-picker-menu {
  opacity: 1;
  position: fixed !important;
  pointer-events: unset;
  /* left: 50% !important; */
  /* top: 50% !important; */
  height: fit-content !important;
  /* transform: translateX(-50%) !important; */
}











/*Meyer reset.css with some modifications*/
/* html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  position: relative;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent
} */

/*Generic animation keyframes*/
@keyframes ease-out-scale {
  0% {
    opacity: 0;
    transform: scale(0);
    z-index: 11
  }

  100% {
    opacity: 1;
    transform: scale(1);
    z-index: 11
  }
}

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translate(40%, 0);
    z-index: 11
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
    z-index: 11
  }
}

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translate(-40%, 0);
    z-index: 11
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
    z-index: 11
  }
}




/*Timepicker: utility rules*/
.tp-hide {
  display: none
}



.page {
  width: fit-content !important;
  max-width: 100%;
}

.page .page-container {
  margin: 0 auto;
  padding: 2%;
  width: 100% !important;
}

/*Timepicker: Root widget*/
.page .widget {
  /* background: #373738; */
  box-shadow: inset 0 2px 1px -1px rgba(255, 255, 255, 0.2), inset 0 -2px 1px -1px rgba(0, 0, 0, 0.2), 0 15px 18px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  height: 450px;
  margin: inherit;
  width: 460px
}

.widget--tp .tp-wrap {
  width: 100%
}

/*Timepicker: widget footer*/
.widget--tp .tp-footer {
  /* background: #373738; */
  border-radius: 0 0 10px 10px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, .2) inset;
  bottom: 0;
  height: 50px;
  left: 0;
  position: absolute;
  right: 0
}

/*Timepicker: widget footer buttons*/
.widget--tp .tp-base {
  bottom: 5px;
  left: 51%;
  position: absolute
}

.widget--tp .tp-base--text {
  /* background: linear-gradient(to bottom, rgba(46, 46, 46, 1) 0%, rgba(34, 34, 34, 1) 100%); */
  /* border: 1px solid #141414; */
  background-color: #E6E6E6;
  color: var(--main-color) !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2), 0 1px 0 rgba(255, 255, 255, .2) inset;
  color: #bcbcbc;
  font: 2.2em/1.3 'Roboto', sans-serif;
  height: 40px;
  text-shadow: 0 2px 0 #555;
  width: 40px
}

.widget--tp .tp-base--handle {
  cursor: pointer;
  bottom: 2px;
  height: 45px;
  margin: 6px 0 0;
  opacity: 0;
  width: 45px;
  z-index: 100
}

.widget--tp .tp-hour,
.widget--tp .tp-hour--handle {
  left: 39%
}

.widget--tp .tp-colon {
  color: var(--main-color);
  left: 48.35%;
  font: 2.2em/1.4 'Roboto', sans-serif
}

/*Timepicker: widget generic hour and min section*/
.widget--tp .tp-section {
  height: 85%;
  margin: 8px 5px;
  opacity: 0;
  position: absolute;
  width: 98%;
  transition: all .3s ease-out;
  /* background-color: red; */
  border-radius: 50%;
  background-color: #E6E6E6;
}

/*
 * Timepicker: widget root sections & child elements lazy anims
 */
.tp-hour--handle:checked~.tp-section--hour {
  animation: slide-in-left 0.3s 0.3s 1 ease-in-out forwards
}

.tp-min--handle:checked~.tp-section--min {
  animation: slide-in-right 0.3s 0.3s 1 ease-in-out forwards
}

.tp-hour--handle:checked~.item-text--hour-1,
.tp-min--handle:checked~.item-text--min-1,
.tp-hour--handle:checked~.item-text--hour-4,
.tp-min--handle:checked~.item-text--min-4,
.tp-hour--handle:checked~.item-text--hour-7,
.tp-min--handle:checked~.item-text--min-7,
.tp-hour--handle:checked~.item-text--hour-10,
.tp-min--handle:checked~.item-text--min-10 {
  animation: ease-out-scale 0.5s 0.5s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
}

.tp-hour--handle:checked~.item-text--hour-2,
.tp-min--handle:checked~.item-text--min-2,
.tp-hour--handle:checked~.item-text--hour-6,
.tp-min--handle:checked~.item-text--min-6,
.tp-hour--handle:checked~.item-text--hour-8,
.tp-min--handle:checked~.item-text--min-8,
.tp-hour--handle:checked~.item-text--hour-12,
.tp-min--handle:checked~.item-text--min-12 {
  animation: ease-out-scale 0.5s 1s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
}

.tp-hour--handle:checked~.item-text--hour-3,
.tp-min--handle:checked~.item-text--min-3,
.tp-hour--handle:checked~.item-text--hour-5,
.tp-min--handle:checked~.item-text--min-5,
.tp-hour--handle:checked~.item-text--hour-9,
.tp-min--handle:checked~.item-text--min-9,
.tp-hour--handle:checked~.item-text--hour-11,
.tp-min--handle:checked~.item-text--min-11 {
  animation: ease-out-scale 0.5s 1.5s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
}

.tp-hour--handle:checked~.item-handle--hour,
.tp-min--handle:checked~.item-handle--min {
  z-index: 100 !important
}

/*Timepicker: generic hour & min blocks*/
.widget--tp .tp-item {
  cursor: pointer;
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  width: 35px
}

.widget--tp .item-text {
  /* background: linear-gradient(to bottom, rgba(46, 46, 46, 1) 0%, rgba(34, 34, 34, 1) 100%); */
  /* border: 1px solid #141414; */
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, .2), 0 1px 0 rgba(255, 255, 255, .2) inset; */
  color: #373738;
  border-radius: 50%;
  font: 300 1.3em/1.8 'Roboto', sans-serif;
  opacity: 0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .6)
}

/*Timepicker: root sections hour & mins buttons alignment*/
.widget--tp .item-handle-1,
.widget--tp .item-text-1 {
  left: 45%;
  top: 3%
}

.widget--tp .item-handle-7,
.widget--tp .item-text-7 {
  left: 45%;
  top: 75%
}

.widget--tp .item-handle-2,
.widget--tp .item-text-2 {
  left: 62%;
  top: 10%
}

.widget--tp .item-handle-6,
.widget--tp .item-text-6 {
  left: 62%;
  top: 68%
}

.widget--tp .item-handle-12,
.widget--tp .item-text-12 {
  left: 28%;
  top: 10%
}

.widget--tp .item-handle-8,
.widget--tp .item-text-8 {
  left: 28%;
  top: 68%
}

.widget--tp .item-handle-3,
.widget--tp .item-text-3 {
  left: 74%;
  top: 25%
}

.widget--tp .item-handle-11,
.widget--tp .item-text-11 {
  left: 16%;
  top: 25%
}

.widget--tp .item-handle-5,
.widget--tp .item-text-5 {
  left: 74%;
  top: 53%
}

.widget--tp .item-handle-9,
.widget--tp .item-text-9 {
  left: 16%;
  top: 53%
}

.widget--tp .item-handle-4,
.widget--tp .item-text-4 {
  left: 82%;
  top: 40%
}

.widget--tp .item-handle-10,
.widget--tp .item-text-10 {
  left: 8%;
  top: 40%
}

.widget--tp .item-handle {
  margin: 0 2px 0 0;
  opacity: 0;
  z-index: -100
}

/*Timepicker: section child button click logic*/
.widget--tp .tp-base.tp-base--hidden {
  background: none;
  color: #fff;
  border: none;
  box-shadow: none;
  opacity: 0;
  text-shadow: none
}

.widget--tp .item-handle--hour-1:checked~.tp-hour-item-1,
.widget--tp .item-handle--min-1:checked~.tp-min-item-1,
.widget--tp .item-handle--hour-2:checked~.tp-hour-item-2,
.widget--tp .item-handle--min-2:checked~.tp-min-item-2,
.widget--tp .item-handle--hour-3:checked~.tp-hour-item-3,
.widget--tp .item-handle--min-3:checked~.tp-min-item-3,
.widget--tp .item-handle--hour-4:checked~.tp-hour-item-4,
.widget--tp .item-handle--min-4:checked~.tp-min-item-4,
.widget--tp .item-handle--hour-5:checked~.tp-hour-item-5,
.widget--tp .item-handle--min-5:checked~.tp-min-item-5,
.widget--tp .item-handle--hour-6:checked~.tp-hour-item-6,
.widget--tp .item-handle--min-6:checked~.tp-min-item-6,
.widget--tp .item-handle--hour-7:checked~.tp-hour-item-7,
.widget--tp .item-handle--min-7:checked~.tp-min-item-7,
.widget--tp .item-handle--hour-8:checked~.tp-hour-item-8,
.widget--tp .item-handle--min-8:checked~.tp-min-item-8,
.widget--tp .item-handle--hour-9:checked~.tp-hour-item-9,
.widget--tp .item-handle--min-9:checked~.tp-min-item-9,
.widget--tp .item-handle--hour-10:checked~.tp-hour-item-10,
.widget--tp .item-handle--min-10:checked~.tp-min-item-10,
.widget--tp .item-handle--hour-11:checked~.tp-hour-item-11,
.widget--tp .item-handle--min-11:checked~.tp-min-item-11,
.widget--tp .item-handle--hour-12:checked~.tp-hour-item-12,
.widget--tp .item-handle--min-12:checked~.tp-min-item-12 {
  animation: ease-out-scale 0.3s 0.3s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
}

/*Timepicker: hour and min arms block*/
/******Absolute Centering technique******/
.widget--tp .tp-arms {
  animation: ease-out-scale 0.5s 2s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  background: linear-gradient(to bottom, rgba(46, 46, 46, 1) 0%, rgba(34, 34, 34, 1) 100%);
  border: 1px solid #141414;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2), 0 1px 0 rgba(255, 255, 255, .2) inset;
  bottom: 0;
  height: 15px;
  left: 0;
  margin: auto;
  opacity: 0;
  position: absolute;
  right: 0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .6);
  top: 0;
  width: 15px
}

/*Timepicker: hour & mins arms */
.widget--tp .tp-arms>.tp-arms-item {
  background: linear-gradient(to bottom, rgba(46, 46, 46, 1) 0%, rgba(34, 34, 34, 1) 100%);
  border: 1px solid #141414;
  left: 42%;
  height: 5px;
  position: absolute;
  top: 45%;
  transform-origin: left top;
  transform: rotate(-90deg);
  transition: transform .6s .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 550%
}

.widget--tp .tp-arms>.tp-arms-min {
  width: 800%
}

/*Timepicker: arms movement on hour & min blocks click logic*/
.item-handle--hour-1:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-1:checked~.tp-arms>.tp-arms-min {
  transform: rotate(-90deg)
}

.item-handle--hour-2:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-2:checked~.tp-arms>.tp-arms-min {
  transform: rotate(-64deg)
}

.item-handle--hour-3:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-3:checked~.tp-arms>.tp-arms-min {
  transform: rotate(-35deg)
}

.item-handle--hour-4:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-4:checked~.tp-arms>.tp-arms-min {
  transform: rotate(-10deg)
}

.item-handle--hour-5:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-5:checked~.tp-arms>.tp-arms-min {
  transform: rotate(13deg)
}

.item-handle--hour-6:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-6:checked~.tp-arms>.tp-arms-min {
  transform: rotate(50deg)
}

.item-handle--hour-7:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-7:checked~.tp-arms>.tp-arms-min {
  transform: rotate(90deg)
}

.item-handle--hour-8:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-8:checked~.tp-arms>.tp-arms-min {
  transform: rotate(130deg)
}

.item-handle--hour-9:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-9:checked~.tp-arms>.tp-arms-min {
  transform: rotate(165deg)
}

.item-handle--hour-10:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-10:checked~.tp-arms>.tp-arms-min {
  transform: rotate(185deg)
}

.item-handle--hour-11:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-11:checked~.tp-arms>.tp-arms-min {
  transform: rotate(210deg)
}

.item-handle--hour-12:checked~.tp-arms>.tp-arms-hour,
.item-handle--min-12:checked~.tp-arms>.tp-arms-min {
  transform: rotate(240deg)
}

/*Timepicker: generic buttons user interaction*/
input[type=radio]+.tp-item,
input[type=radio]+.tp-base {
  transition: all .3s ease-in-out
}

/* input[type=radio]:hover+.tp-item,
input[type=radio]:hover+.tp-base, */
input[type=radio]:focus+.tp-item,
input[type=radio]:focus+.tp-base {
  /* background: linear-gradient(to bottom, rgba(64, 64, 64, 1) 0%, rgba(47, 47, 47, 1) 100%); */
  /* border-color: #222; */
  /* color: #d0d0d0; */
  /* text-shadow: 0 1px 0 rgba(0, 0, 0, .7) */
}

/* input[type=radio]:active+.tp-item,
input[type=radio]:active+.tp-base, */
input[type=radio]:checked+.tp-item {
  background-color: #E6E6E6;

  /* background: linear-gradient(to bottom, rgba(23, 23, 23, 1) 0%, rgba(40, 40, 40, 1) 100%); */
  /* color: var(--main-color); */
  background-color: var(--main-color);
  color: white !important;
  /* box-shadow: 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(0, 0, 0, .8) inset; */
  font-weight: bold;
  /* text-shadow: 0 1px 0 rgba(0, 0, 0, .3) */
}

.conftime {
  position: fixed;
  bottom: 0px;
  background-color: white;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
  transform: translateY(100%);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.conftime>svg {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 5555;
  cursor: pointer;
  font-size: 22px;
}

.conftime.active {
  transform: translate(-50%, 0px);
  /* transform: translateY(0%); */
}

.conf2time {
  position: fixed;
  bottom: 0px;
  background-color: white;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
  transform: translateY(100%);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.conf2time.active {
  transform: translate(-50%, 0px);
  /* transform: translateY(0%); */
}

.css-1ghilo0-MuiStack-root {
  align-items: center;
}

.css-1aj9me-MuiPickersLayout-root .MuiPickersLayout-actionBar {
  grid-column: 1/4;
  grid-row: 3;
  display: none;
}

.css-1aj9me-MuiPickersLayout-root {
  display: flex !important;
  grid-auto-columns: max-content auto max-content;
  grid-auto-rows: max-content auto max-content;
  overflow: hidden;
  min-width: 320px;
  background-color: #fff;
  flex-direction: column-reverse !important;
}

.css-1aj9me-MuiPickersLayout-root .MuiPickersLayout-toolbar {
  grid-column: 1;
  grid-row: 2/3;
  margin: auto !important;
}

.css-2x8kvt-MuiPickersArrowSwitcher-root-MuiTimeClock-arrowSwitcher {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 6px !important;
  top: 5px !important;
}

.css-1hbyad5-MuiTypography-root {
  margin: auto !important;
}

.css-1r3tc0d-MuiTimePickerToolbar-hourMinuteLabel {
  margin: auto !important;
}

.cities_select {
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: white;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.add_city h4,
.enter_region h4,
.enter_location h4,
.my_addresses h4 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.add_city h4>* {
  margin: 0px;
}

.add_city>h4>span:last-child,
.enter_region h4 span:last-child,
.enter_location h4 span:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-color);
}

.enter_region,
.enter_location,
.my_addresses {
  margin-bottom: 20px;
}

.enter_region input,
.enter_location input,
.my_addresses input {
  border: 1px solid #ccc;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
}

.enter_location>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.enter_location>div button {
  background-color: var(--main-color);
  color: white;
  padding: 6px 10px;
}

.flaotmap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  min-width: 500px;
  min-height: 400px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  gap: 10px;
  z-index: 999;
}

.flaotmap>svg {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 22px;
}

.flaotmap button {
  background-color: var(--main-color);
  color: white;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.waytogetorder .MuiPickersLayout-root.MuiPickersLayout-landscape.css-13z26tl {
  display: flex !important;
  flex-direction: column-reverse !important;
  align-items: center !important;
  justify-content: center !important;
}

.waytogetorder .css-12ha4i7,
.waytogetorder .css-7lip4c,
.waytogetorder .css-118whkv {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.waytogetorder .css-13z26tl .MuiPickersLayout-actionBar,
.waytogetorder .css-1bzq5ag,
.waytogetorder .css-a1rc6s {
  display: none !important;
}

.waytogetorder .css-1kq7u7p {
  background: var(--main-color) !important;
  color: white !important;
  padding: 10px 0 !important;
}