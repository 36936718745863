.auth {
  min-height: 300px;
}
form {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: min(400px, 100%);
  margin: auto;
}
.inputField label {
  display: block;
  margin: 10px 0;
}

.inputField input {
  padding: 10px;
}

form label,
form .rs-input,
.inputField,
form .rs-input-group {
  width: 100% !important;
}

.terms_and_con .rs-input-group,
.terms_and_con .rs-input {
  width: 43px !important;
  height: 34px !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
}

.rs-input[type="checkbox"]:checked {
  background-color: var(--main-color); /* Change the background color when checked */
  border-color: var(--main-color); /* Change the border color when checked */
}

button.btn.auth-btn {
  width: 100%;
  padding: 10px;
  margin-top: 118px;
  background: var(--main-color);
  color: white;
}

.arabicContent .terms_and_con {
  margin-left: auto;
}

.EnglishContent .terms_and_con {
  margin-right: auto;
}

button.btn.auth-btn.rig-auth-btn {
  margin-top: 40px !important;
} 

.rig-auth form{
  margin: 20px auto;
}

.auth.login.rig-auth {
  min-height: 100vh !important;
}

.terms_and_con a {
  color: var(--main-color);
}

