.rs-dropdown-toggle,
.changeBranch,
.header_co a.cart {
  background: var(--main-color);
  color: white !important;
  font-weight: 500;
  font-size: 19px;
  padding: 5px;
  border-radius: 5px;
}

.changeBranch {
  padding: 4px 15px;
}

.rs-dropdown-item:hover .branch_item{
  color: black !important;
}

.rs-dropdown-item,
.rs-dropdown-item:active,
.rs-dropdown-item:focus,
.rs-dropdown-item:hover {
  text-decoration: none;
  color: white;
}

.rs-btn-icon.rs-btn {
  line-height: 16px;
  padding: 10px;
  background: white;
  border: 1px solid var(--main-color);
  font-size: 21px;
  border-radius: 6px !important;
}

.rs-btn-icon.rs-btn:hover {
  background: transparent
}

/* .rs-dropdown-item {
  color: white !important;
}

.rs-dropdown-item:active, .rs-dropdown-item:focus, .rs-dropdown-item:hover, .rs-dropdown-item.rs-dropdown-item-active{
color:black !important;
} */
.showmodelbranch {
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  color: black;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
  border-radius: 6px;
  min-width: 300px;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.showmodelbranch .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  flex-wrap: wrap;
}

.showmodelbranch .actions button {
  width: 100px;
  max-width: 100%;
  padding: 5px;
  border-radius: 4px;
  color: white;
}

.showmodelbranch .actions button:first-of-type {
  background-color: var(--main-color);
}

.showmodelbranch .actions button:last-of-type {
  background-color: red;
}