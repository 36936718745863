/* hire */
.header_father {

  width: min(1000px, 100%) !important;
  margin: auto;
  position: fixed;
  top: 0px;
  background-color: white;
  z-index: 9999;
  height: fit-content;
  margin-bottom: 40px !important;
  padding: 20px;
  height: 70px !important;
  max-height: fit-content;
  width: 100% !important;
  max-height: fit-content !important;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  gap: 20px;
  width: 100% !important;
  z-index: 9999;
  max-height: fit-content;
  width: 70% !important;
  margin: auto;
  /* justify-content: center !important; */
}

.header_co {
  display: flex;
  align-items: center;
  gap: 15px;
  /*  */
}

a.cart {
  padding: 6px 13px !important;
  font-size: 21px;
}

.cart {
  position: relative;
}

.cart>p {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: white;
  color: black;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: -3; */
}

@media (max-width: 600px) {
  .header_co {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    gap: 6px !important;
  }

  .changeBranch {
    padding: 4px;
    font-size: 13px !important;
  }

  a.covertToLogin img {
    width: 30px !important;
  }

  a.covertToLogin {
    font-size: 13px !important;
  }

  .rs-btn-icon.rs-btn {
    line-height: 15px;
    padding: 4px !important;
  }

  span#toggle_side {

    padding: 2px !important;

  }

  .changeBranch {
    padding: 4px 5px !important;
  }

  a.covertToLogin span {
    margin: 0 4px !important;
  }

  header {
    padding: 10px 5px !important;
  }

  .product_text p {
    font-size: 10px !important;
  }

  .edit_in_cart {
    /* flex-wrap: wrap; */
    justify-content: center !important;
    padding-bottom: 20px !important;
  }

  .awssld__container {
    height: 100% !important;
  }

  .package_container {
    padding: 10px !important;
  }

  .addB {
    margin: auto !important;
  }

  a.cart {
    padding: 3px 4px !important;
    font-size: 21px;
  }

  .changeBranch {
    /* padding: 4px 15px; */
    font-size: 12px;
  }

  .inputs_p label {
    font-size: 13px !important;
  }

  .text_4 {
    padding: 17px 10px !important;
  }

  .arabicContent .home_id,
  .arabicContent .searchbox,
  .arabicContent .cateoriesHome {
    padding-right: 40px !important;
  }

  h2 {
    font-size: 18px !important;
    line-height: 50px !important;
  }

  .order_btns button {

    padding: 10px 66px !important;

  }

  .cartItems_container .edit_in_cart_c>div {

    font-size: 17px !important;
  }

  .arabicContent .home_id,
  .arabicContent .searchbox,
  .arabicContent .cateoriesHome {
    padding-right: 19px !important;
  }

  .products_card {
    width: 100%;
    padding: 4px !important;
  }

  .changePrice {
    font-size: 18px !important;
  }

  #root>div.siteContent.arabicContent>div>div>div>div>div:nth-child(1)>div>div>div>div.edit_in_cart>div>span {
    font-size: 17px !important;
    /* white-space: nowrap !important; */
  }

}

.cartItems_container .edit_in_cart_c>div span {
  white-space: nowrap;
}

.product_card .product_image,
.product_card .product_image img {
  height: 100%;
  width: 235px !important;
}

#root>div.siteContent>div>div>div>div>div>div>div>div>div>div.product_image,
.cartItems_container .product_card .product_image img {
  width: 100% !important;
}

#root>div.siteContent>div>div>div>div.cartItems_container>div>div>div>div>div>div.product_image {
  /* height: 250px !important; */
  overflow: hidden;
}

.rs-dropdown-item.rs-dropdown-item-active,
.rs-dropdown-item.rs-dropdown-item-active:focus,
.rs-dropdown-item.rs-dropdown-item-active:hover {
  background-color: #f2faff !important;
  /* background-color: var(--rs-dropdown-item-bg-active); */
  color: var(--main-color) !important;
  /* color: var(--rs-dropdown-item-text-active); */
}

/*
.rs-dropdown-item.rs-dropdown-item,
.rs-dropdown-item.rs-dropdown-item:focus, */
.rs-dropdown-item.rs-dropdown-item:hover {
  background-color: #f2faff !important;
  /* background-color: var(--rs-dropdown-item-bg-active); */
  color: var(--main-color) !important;
  /* color: var(--rs-dropdown-item-text-active); */
}

/* .rs-dropdown-item */

.header_co .rs-btn-icon {
  background-color: transparent !important;
}
@media (min-width:757px) {
  .searchbox{
    margin-top: -57px !important;
  }
}
@media (max-width:757px) {
  #root>div.siteContent>div>div>div>div.cartItems_container>div>div>div>div>div>div.product_image {
    /* height: 170px !important; */
    overflow: hidden;
  }

  header {
    width: 100% !important;
  }

  .header_father {
    height: fit-content;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.rs-anim-fade {
  position: absolute !important;
  top: 30px !important;
  height: fit-content !important;
}

.rs-anim-fade.rs-anim-in.rs-popover.rs-popover-full.placement-bottom-start {
  top: 71px !important;
  z-index: 122343565465465;
  position: fixed !important;
}

@media (max-width:565px) {
  .header_father {
    height: fit-content;
  }
}