.address {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 20px;
}


.address .address_right>h4 {
  font-size: 18px;
  font-weight: bold;
  color: #4d4d4d;
}

.address .address_right>button {
  background: rgba(24, 136, 127, 0.1);
  color: #18887F;
  margin-top: 20px;
  padding: 10px 30px;
  font-size: 17px;
  border-radius: 6px;
}

.modal_p .rs-modal-body form button{
  height: inherit !important;
}

.address .address_left {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.address_left .edit {
  border: 1px dashed var(--main-color);
  width: 100px;
  max-width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 7px;
  justify-content: center;
  color: var(--main-color);
  padding: 4px;
  border-radius: 4px;
  background-color: transparent;
}

.address_left .delete {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #D31E4B;
  padding: 4px;
  border-radius: 4px;
}