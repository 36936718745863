.paymentmethod {
  width: 580px;
  max-width: 100%;
  margin: 30px auto;
  text-align: center;
}

.paymentmethod .payment {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: #F6F6F6;
  padding: 20px;
  border-radius: 8px;
}

.paymentmethod .payment>div {
  display: flex;
  align-items: center;
  gap: 14px;
}

.payment .payment_right {
  gap: 20px !important;
}

.payment .payment_right .payselect {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--main-color);
  cursor: pointer;
}

.payselect.active {
  background-color: var(--main-color);
}

.conpaybtn {
  background-color: var(--main-color);
  width: 400px;
  margin: 30px;
  padding: 20px;
  color: white;
  border-radius: 8px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  color: #ffffff;
  cursor: pointer;
}