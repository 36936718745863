.branch>h5 {
  font-weight: bold;
  font-size: 21px;
  margin-bottom: 10px;
  font-style: normal;
  text-align: right;
  color: #4d4d4d;
}

.branch .branch_work_time {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.branch_work_time .clock {
  background-color: var(--main-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

}

.branch_work_time>h5 {
  color: var(--main-color);
  font-size: 14px;
  font-weight: 400;
}

.branch .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.branch .actions>div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.branch .actions button {
  width: 98px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
}

.branch .actions>button {
  background-color: var(--main-color);
  color: white;
}

.branch .actions>div button {
  background: rgba(24, 136, 127, 0.1);
  color: #18887F;
  border: 1px solid #18887F;
}

.branch .actions>div button.closed {
  background-color: rgba(211, 30, 75, 0.1);
  color: #d31e4b;
  border: 1px solid #d31e4b;
}

.zoominout {
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  right: 10px;
}

.zoominout>button {
  width: 25px;
  height: 25px;
  font-size: 20px;
  background-color: white;
  cursor: pointer;
}