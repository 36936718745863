.slides {
  /* min-height: 60vh; */
  display: flex;
  flex-direction: column;
  /* height: 47vh !important; */
  /* justify-content: center; */
}

.slideHome,
.slideHome img {
  width: 100%;
  height: 100%;
}

.home_id {
  display: flex;
  align-items: center;
  width: min(1000px, 100%) !important;
  z-index: 9;
  margin: auto;
}

.home_id .lg-sm {
  position: relative;
  top: -71px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* background: var(--main-color); */
  border-radius: 120px;
  width: 140px;
  /* height: 140px; */
}

.home_id .lg-sm img {
  width: 100%;
}

.home_id .home-text {
  position: relative;
  top: -40px;
}

.slideHome {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.each-slide-effect {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 283px;
  /* height: fit-content; */
  /* margin: 10px 0; */
  width: 100%;
}

.awssld__controls {
  height: 43px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background-size: cover;
  height: 100%;
}

.each-slide-effect span {
  padding: 4px 20px;
  font-size: 20px;
  text-align: center;
  background: white;
  border-radius: 7px;
}

.each-slide-effect span,
.each-slide-effect p {
  color: var(--main-color);
}

/*  */
.childs > div {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.searchbox .rs-input-group {
  width: min(697px, 100%);
  box-shadow: 1px 0px 24px -2px #e5e5ea;
  border-radius: 5px;
}

.searchbox {
  width: 100%;
  padding: 24px 10px;
  background: #f2f2f5;
  display: flex;
  gap: 13px;
  margin: 13px 0;
  /* justify-content: center; */
  align-items: center;
  margin-top: -50px;
  margin-bottom: 0;
  /* padding-left: 70px; */
}

button {
  min-width: fit-content;
}

.arabicContent .home_id,
.arabicContent .searchbox,
.arabicContent .cateoriesHome {
  /* padding-right: 70px !important; */
}

.EnglishContent .home_id,
.EnglishContent .searchbox,
.EnglishContent .cateoriesHome {
  padding-left: 10px !important;
}

.arabicContent .home_id .home-text {
  margin-right: 30px;
}

.EnglishContent .home_id .home-text {
  margin-left: 30px;
}

.cateoriesHome::-webkit-scrollbar {
  height: 4px;
  /* Width of the scrollbar */
}

.cateoriesHome::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Color of the track (the area behind the scrollbar) */
}

.cateoriesHome::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 5px;
  /* Rounded corners for the scrollbar thumb */
}

.cateoriesHome button {
  background: white;
  border: 0.1px solid var(--main-color);
}

.cateoriesHome button:hover {
  background-color: transparent !important;
  color: var(--main-color) !important;
}

.awssld {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 180px;
  height: 283px;
  z-index: 1;
}

.addCart {
  background: var(--main-color) !important;
  width: 31px;
  height: 31px;
}

/* .addCart:hover {
  background: var(--main-color) !important;
} */

.addCart img {
  width: 100%;
}

.products_card {
  width: min(1000px, 100%) !important;
  margin: auto;
  /* padding: 17px; */
}

/* .home_id {
  width: min(1000px, 100%) !important;
  margin: auto;
} */

.product_card {
  display: flex;
  /* gap: 7px; */
  width: min(467px, 100%);
  /* height: 196px; */
  box-shadow: 1px 0px 24px 2px #e5e5ea;
  border-radius: 8px;
}

.product_card .product_image,
.product_card .product_image img {
  height: 100%;
}

.product_details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
}

.product_price_details {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  color: var(--main-color);
}

.product_price_details p {
  font-size: 12px;
}

.product_text {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product_text p {
  margin: 0;
}

.products_cards_container,
.products_cards_container .infinite-scroll-component,
.products_cards_container .infinite-scroll-component > div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: min(1000px, 100%) !important;
  gap: 13px;
  margin: auto !important;
  /* row-gap: 30px; */
}

.products_cards_container .infinite-scroll-component .infinteFavourite {
  margin-top: 39px !important;
}

.products_cards_container .infinite-scroll-component > div {
  padding: 0 10px;
}

.products_cards_container .infinite-scroll-component {
  flex-direction: column;
}

span.rs-modal-header-close.rs-btn-close {
  font-size: 19px;
  display: flex;
}

.modal_p .rs-modal-body form {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  gap: 0 !important;
}

.modal_p .rs-modal-body form button {
  margin: 0 !important;
  width: 38px !important;
  height: 38px !important;
  border-radius: 4px;
}

.modal_p .rs-modal-body form .css-b62m3t-container {
  width: min(300px, 100%);
}

.rs-modal-body {
  overflow: visible !important;
}

.package_container {
  background: rgba(0, 0, 0, 0.404);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999999999999;
  left: 0;
  overflow: auto;
  padding: 17px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.productDetails {
  min-height: 100%;
  width: min(600px, 100%);
  background: white;
  /* border-radius: 5px; */
}

.productDetails .product_image {
  /* min-height: 391px; */
  width: 100%;
}

.productDetails .product_image .product_text {
  padding: 20px;
}

.productDetails .product_image img {
  width: 100%;
  height: auto;
}

.productDetails .awssld.awssld--organic-arrows {
  height: 100% !important;
}

/* hir */
/* .productDetails .each-slide-effect {

  height: 400px !important;

} */

.productDetails > div:not(:last-child) {
  border-bottom: 1px solid#707070;
}

.text_2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 12px;
  padding-bottom: 3px;
}

.text_2 p.with_underLine span.text_3 {
  position: relative;
  padding-bottom: 10px;
  display: block;
  color: #4d4d4d;
  width: fit-content;
}

.text_2 p.with_underLine span:not(.text_3) {
  color: #a0a0a0;
  font-size: 16px;
}

.text_2 p.with_underLine span.text_3:before {
  content: "";
  position: absolute;
  bottom: 2px;
  right: 0;
  width: 76%;
  height: 4px;
  background: var(--main-color);
  border-radius: 0px;
}

.product_d .rs-input {
  width: 27px !important;
  height: 27px !important;
  margin: 3px 0;
}

.text_4 {
  padding: 17px;
}

.rs-input[type="radio"]:checked {
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.text_4,
.terms_and_con.product_d {
  justify-content: space-between;
  width: 100%;
}

.terms_and_con.product_d > div {
  display: flex;
  gap: 6px;
  align-items: center;
}

.edit_in_cart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.changePrice .price_ch {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  color: #4d4d4d;
  border: 0.5px solid#4D4D4D;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
}

.changePrice {
  width: 218px;
  padding: 10px 14px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-around;
  background: #e6e6e6;
  margin: 20px 0;
  font-size: 19px;
}

.addB {
  background: var(--main-color);
  padding: 10px 14px;
  width: min(300px, 100%);
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 15px;
  cursor: pointer;
}

.product_text:last-child {
  border-bottom: none;
}

.productDetails form {
  width: 100% !important;
  padding: 0 10px;
}

.productDetails form button {
  margin: 0 !important;
}

button {
  user-select: none;
}

button.btn-close {
  position: absolute;
  background: white;
  width: 30px;
  height: 30px;
  z-index: 999;
  font-size: 22px;
  color: black;
}

.awssld__content {
  align-items: flex-start !important;
  background-color: transparent !important;
}

.rs-anim-fade.rs-anim-in {
  top: 55.6px !important;
}

.width_on {
  /* overflow: auto; */
  width: min(1000px, 100%) !important;
  white-space: nowrap;
  padding: 5px;
  display: flex;
  z-index: 9;
  margin: auto;
  gap: 9px;
  padding-bottom: 12px;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.searchbox .width_on .rs-input-group {
  width: 100%;
}

.searchbox .width_on {
  justify-content: space-between;
  align-items: center;
}

.slick-slide img {
  display: block;
  width: 100% !important;
}

@media (max-width: 767px) {
  .home-text h2 {
    line-height: 21px !important;
    font-size: 14px !important;
  }

  .slides {
    height: fit-content !important;
    max-height: fit-content !important;
  }

  .each-slide-effect {
    height: 201px;
  }

  .awssld {
    height: 200px;
  }
}

.discount {
  min-width: fit-content !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* hir */
/* @media (max-width: 500px) {
  #root>div.siteContent.arabicContent>div.childs>div>div:nth-child(2) {
    height: 330px !important;
  }
} */

/* .catbtn {
  transition: .5s all ease-in-out;
}

.catbtn:hover {
  background-color: transparent !important;
  color: var(--main-color) !important;
} */
.catbtn {
  transition: all 0.5s ease-in-out !important;
}

.catbtn:hover {
  background-color: transparent !important;
  color: var(--main-color) !important;
}

.rs-btn-default {
  transition: all 0.5s ease-in-out !important;
}

.rs-btn-default:hover {
  background-color: transparent !important;
  color: var(--main-color) !important;
}

.rs-btn {
  transition: all 0.5s ease-in-out !important;
}

.rs-btn:not(.addCart):hover {
  background-color: transparent !important;
  color: var(--main-color) !important;
}

.cateoriesHome button:hover {
  background-color: var(--main-color) !important;
  color: white !important;
}

.awssld__bullets {
  position: absolute;
  bottom: 20px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999999999 !important;
  /* position: relative ; */
}

.awssld__bullets button {
  cursor: pointer;
  color: white;
  background-color: white !important;
}

.searchbox .rs-input-group-addon {
  background: transparent !important;
}

.searchbox .rs-input-group-addon svg {
  font-size: 25px;
  margin-left: 12px;
}

.Toastify__toast-container.Toastify__toast-container--top-right {
  width: min(600px, 100%);
  font-size: 23px !important;
  top: 15%;
  min-height: 100px !important;
}

.Toastify__toast-container.Toastify__toast-container--top-right > * {
  min-height: 80px;
}

.addCart:hover {
  background: var(--main-color) !important;
}

.productDetails .awssld.awssld--organic-arrows,
.productDetails .awssld__wrapper,
.productDetails .awssld__container,
.productDetails .awssld__box,
.productDetails .awssld--active,
.productDetails .awssld__content {
  /* height: 500px !important; */
}

/* .productDetails .each-slide-effect {
  align-items: flex-start !important;
} */
@media (max-width: 767px) {
  .searchbox {
    margin-top: -58px !important;
  }
}

.products_cards_container .infinite-scroll-component > div {
  overflow: hidden;
}

/* .childs,
.childs>div {
  overflow: hidden !important;
} */

@media (max-width: 450px) {
  .home_id .lg-sm {
    position: relative;
    top: -54px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* background: var(--main-color); */
    border-radius: 120px;
    width: 90px;
    /* height: 140px; */
  }

  .searchbox {
    margin-top: -50px !important;
  }

  .arabicContent .home_id .home-text {
    margin-right: 13px;
  }

  .home_id .home-text {
    position: relative;
    top: -30px;
  }
}

/* .searchbox */
.each-slide-effect2 {
  height: fit-content !important;
}

.slick-dots {
  bottom: 10px !important;
}

.slick-dots li button:before {
  font-size: 20px !important;
}

.firstlyLogin {
  font-size: 24px;
  margin: 40px auto;
}

.home-o {
  overflow: hidden;
}
