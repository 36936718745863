.add_location_form {
  margin-top: 50px;
  padding: 20px;
}

/* .add_location_form label{
  text-align: end;
} */

/* .add_location_form form {
  display: flex;
  flex-direction: column;
} */
.add_location_form form {
  width: 500px;
  max-width: 100%;
}

.add_location_form form>div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 10px 0;
}

.add_location_form form input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  width: 100%;
}

.add_location_form form .city_div {
  position: relative;
}

.city_div {
  width: 100% !important;
  min-height: 10px !important;
}

.city_div>div {
  /* background-color: var(--main-color); */
  /* border-radius: 50%; */
  color: black !important;
  cursor: pointer;
  height: 100% !important;
  justify-content: center;
  left: 10px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  min-height: 50px;
  margin-top: 20px;
}

.arelocdef {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rs-toggle-checked .rs-toggle-presentation {
  background-color: var(--main-color) !important;
  background-color: var(--rs-toggle-checked-bg);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  color: var(--rs-toggle-checked-thumb);
}