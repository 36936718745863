.conditions {
  margin-top: 40px;
  /* padding: 0px 30px; */
  text-align: start;
  width: min(1000px, 100%) !important;
  margin: 40px auto 0;
}

.conditions h6 {
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 700;
  text-align: start;
}

.conditions p {
  font-weight: 400;
  font-size: 15px;
  text-align: start;
}