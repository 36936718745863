.showpass {
  position: relative;
}

.showpass svg {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: var(--main-color);
  cursor: pointer;
}

.resset-btn {
  margin-top: 200px;
  margin-bottom: -40px;
}