.changepass {
  width: 100%;
  text-align: center;
  border: 3px dashed var(--main-color);
  padding: 10px;
  border-radius: 8px;
}

.changepass>h4 {
  color: var(--main-color);
  font-size: 18px;
}

.pointnumber_div {
  background-color: #fbcebd;
  width: 570px;
  max-width: 100%;
  margin: auto;
  color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px;
  margin-top: 20px;
  border-radius: 10px;
  margin-bottom: 50px;
}

.pointnumber_div>div {
  display: flex;
  align-items: center;
  gap: 20px;

}

.pointnumber_div>div>* {
  font-size: 17px;
}

.pointnumber_div>div:last-of-type>* {
  font-size: 17px;
  margin: 0px;
}

.city_div {
  position: relative;
}

.city_div .city_chev {
  position: absolute;
}