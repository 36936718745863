.showpass {
  position: relative;
}

.showpass svg {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: var(--main-color);
  cursor: pointer;
}

.resset-btn {
  margin-top: 200px;
  margin-bottom: -10px;
  margin-top: 30px !important;
}

.contact_Form {
  margin-top: 30px;
}

.contact_Form form {
  width: 400px;
  max-width: 100%;
  margin: 10px auto;
}

.contact_Form form>div {
  margin-bottom: 15px;
  width: 100%;
}

.contact_Form form>div label {
  font-size: 17px;
  font-weight: normal;
  color: #4d4d4d;
}

.contact_Form form>div input {
  width: 100%;
  margin-top: 4px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.contact_Form form>div textarea {
  width: 100%;
  height: 120px;
  resize: none;
  margin-top: 4px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.contact_Form form button {
  width: 100%;
  height: 60px;
  color: white;
  border-radius: 8px;
  background: var(--main-color)
}