a.covertToLogin {
  display: flex;
  align-items: center;
  color: slategrey;
  text-decoration: none;
}

a.covertToLogin svg {
  font-size: 28px;
}

a.covertToLogin span {
  margin: 0 10px;
}
