.branches {
  width: 500px;
  max-width: 100%;
  margin: 20px auto;
}

.branches .branch {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}