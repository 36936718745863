.cartItems {
  min-height: 108vh !important;
}

.loginFirst a {
  width: min(280px, 100%);
  background: var(--main-color);
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 19px;
  border-radius: 7px;
  flex-direction: column;
}

.loginFirst {
  height: 46vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

/* .loginFirst img {
  width: 180px;
  height: 180px;
  background: #c5c6c7;
  padding: 17px;
  border-radius: 200px;
} */

.profileNotFound span {
  padding: 36px;
  border-radius: 200px;
  background: #c5c6c7;
  width: 218px;
  height: 218px;
  display: block;
}

.profileNotFound span img {
  width: 100%;
  height: 100%;
}

.profileNotFound p {
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  margin: 14px 0;
}

.cartItems_container .product_card {
  width: min(1000px, 100%) !important;
  box-shadow: none;
  height: fit-content !important;
  display: flex;
  flex-direction: column;
}

.cartItems_container .product_card .product_image,
.cartItems_container .product_card .product_image img {
  width: 246px;
  margin: 0 !important;
  height: 100%;
}

.cartItems_container .product_card>div {
  width: 100%;
  display: flex;
  gap: 10px;
}

.cartItems_container .product_card .changePrice {
  background: none !important;
  margin: 0;
  justify-content: center !important;
}

.cartItems_container .product_card .edit_in_cart {
  background: #e6e6e6;
}

/*     background: #e6e6e6;
 */

.ext_c {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 700px) {
  .cartItems_container .product_card>div:first-child {
    /* flex-direction: column; */
  }
}

.cartItems_container {
  padding: 10px 0;
  /* border-bottom: 1px solid#707070; */
}

.cartItems_container>.product_text {
  width: min(1000px, 100%) !important;
  margin: auto;
  padding: 12px;
  border-bottom: 1px solid#707070;
}

.cartItems_container>.product_text_c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* text_2 */
.cartItems_container>.product_text_c .text_2 {
  width: fit-content !important;
}

.cartItems_container>.product_text_c .select_c {
  padding: 4px 19px;
  border: 2.3px solid;
  color: var(--main-color);
  font-size: 22px;
  border-radius: 8px;
  cursor: pointer;
  width: fit-content;
}

.cartItems_container .edit_in_cart_c>div {
  width: min(1000px, 100%) !important;
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
  color: white !important;
  font-size: 22px;
  align-items: center;
}

.cartItems_container .edit_in_cart_c {
  flex-direction: column;
}

/*  */
.cartItems_container .edit_in_cart_c>div:first-child,
.cartItems_container .edit_in_cart_c>div:nth-child(3) {
  background: #4d4d4d;
  margin-bottom: 4px !important;

}

.cartItems_container .edit_in_cart_c>div:last-child,
.cartItems_container .edit_in_cart_c>div:nth-child(2) {
  background: var(--main-color);
}

.cartItems_container .edit_in_cart_c>div span {
  color: white !important;
}

.order_btns {
  width: min(1000px, 100%) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px auto;
  flex-wrap: wrap
}

.order_btns button {
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 89px;
  border: 2.3px solid;
  transition: 0.6s ease-in-out;
  border-radius: 8px;
  cursor: pointer;
}

.order_btns .continue_o,
.order_btns .add_more_o:hover {
  color: white;
  background: var(--main-color);
}

.order_btns .add_more_o,
.order_btns .continue_o:hover {
  color: var(--main-color);
  background: transparent;
}

@media (max-width:767px) {
  .order_btns button {
    width: 90% !important;
    margin: auto;
    margin-bottom: 10px !important;
  }
}