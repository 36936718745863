.newacc {
  height: 37vh !important;
  /* padding: 40px 0px !important; */
}

.loginFirst a {
  width: min(280px, 100%);
  background: var(--main-color);
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 19px;
  border-radius: 7px;
  flex-direction: column;
}

.loginFirst {
  height: 55vw !important;
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
  height: fit-content !important;
}

/* .loginFirst img {
  width: 180px;
  height: 180px;
  background: #c5c6c7;
  padding: 17px;
  border-radius: 200px;
} */

.profile_found button {
  background: var(--main-color);
  color: white;
  margin: 14px 4px;
}

.myaccbtn {
  margin: 4px !important;
}

.auth.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 130vh !important;
}

.city_div .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  color: black;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100% !important;
  border-radius: 0 !important;
  padding: 20px !important;
  margin: 24px 0px;
  background: none !important;
}

.edit_loc.rs-modal-body {
  max-height: 30000vh !important;
}

.del_acc {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.delete_account {
  width: min(300px, 100%);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  position: fixed;
  background: white;
  box-shadow: 0px 12px 32px -16px;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  border-radius: 6px;
}

.delete_account button {
  width: 100% !important;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0000007a;
}

.btns {
  display: flex;
  margin-right: auto;
  gap: 5px;
}

.btns button {
  color: black !important;
  background-color: transparent !important;
  border: 1px solid var(--main-color) !important;
  padding: 5px 20px !important;
}

.btns button:first-child {
  background-color: rgba(128, 128, 128, 0.479) !important;
  color: grey !important;
  border: 1px solid grey !important;
}

.downappfirst {
  min-height: fit-content !important;
}

.editbtn {
  margin-top: 10px !important;
}

.accountnumber {
  margin-bottom: 10px !important;
}

.signbtn {
  transition: .5s all ease-in-out;
  margin-top: 70px !important;
}

.signbtn:hover {
  color: var(--main-color) !important;
  background-color: transparent !important;
}