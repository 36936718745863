.loginFirst a {
  width: min(280px, 100%);
  background: var(--main-color);
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 19px;
  border-radius: 7px;
  flex-direction: column;
}

.cartItems_container .product_card .edit_in_cart .Toastify {
  display: none !important;
}



.loginFirst {
  height: 46vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

/* .loginFirst img {
  width: 180px;
  height: 180px;
  background: #c5c6c7;
  padding: 17px;
  border-radius: 200px;
} */

.profileNotFound p {
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  margin: 14px 0;
}

.cartItems_container .product_card {
  width: min(850px, 100%) !important;
  box-shadow: none;
  height: fit-content !important;
  display: flex;
  flex-direction: column;
}

.cartItems_container .product_card .product_image,
.cartItems_container .product_card .product_image img {
  width: 246px;
  /* margin: auto; */
  height: 100%;
}

.cartItems_container .product_card>div {
  width: 100%;
  display: flex;
}

.cartItems_container .product_card .changePrice {
  background: none !important;
  margin: 0;
  justify-content: center !important;
}

.cartItems_container .product_card .edit_in_cart {
  background: #e6e6e6;
}

/*     background: #e6e6e6;
 */

.ext_c {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 700px) {
  .cartItems_container .product_card>div:first-child {
    /* flex-direction: column; */
  }

  #root>div.siteContent>div>div>div>div.cartItems_container>div>div>div>div>div>div.product_image {
    /* width: 200px !important; */
  }
}

@media (max-width: 742px) {
  .loginFirst {
    height: 76vw !important;
  }
}

@media (max-width: 470px) {
  .loginFirst {
    height: 107vw !important;
  }
}

@media (max-width: 985px) {
  .product_card {
    width: min(450px, 100%) !important;
  }
}


@media (max-width: 952px) {
  .product_card {
    width: min(435px, 100%) !important;
  }
}

@media (max-width: 933px) {
  .product_card {
    width: min(430px, 100%) !important;
  }
}

@media (max-width: 911px) {
  .product_card {
    width: 418px !important;
  }
}

@media (max-width: 886px) {
  .product_card {
    width: 408px !important;
  }
}

@media (max-width: 867px) {
  .product_card {
    width: min(390px, 100%) !important;
  }
}


@media (max-width: 830px) {
  .product_card {
    width: 385px !important;
    /* height: 170px !important; */
  }

  .product_card .product_image,
  .product_card .product_image img {
    height: 100%;
    width: 203px !important;
  }
}

@media (max-width: 821px) {
  .product_card {
    /* height: 170px !important; */
    width: 367px !important;
  }
}

@media (max-width: 785px) {
  .product_card {
    /* height: 170px !important; */
    width: 347px !important;
  }
}
@media (max-width: 740px) {
  .product_card {
    width: 96% !important;
    margin: auto !important;
  }
}

.cartItems_container {
  padding: 10px 0;
  /* border-bottom: 1px solid#707070; */
}

.cartItems_container>.product_text {
  width: min(850px, 100%) !important;
  margin: auto;
  padding: 12px;
  border-bottom: 1px solid#707070;
}

.cartItems_container>.product_text_c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* text_2 */
.cartItems_container>.product_text_c .text_2 {
  width: fit-content !important;
}

.cartItems_container>.product_text_c .select_c {
  padding: 4px 19px;
  border: 2.3px solid;
  color: var(--main-color);
  font-size: 22px;
  border-radius: 8px;
  cursor: pointer;
  width: fit-content;
}

.cartItems_container .edit_in_cart_c>div {
  width: min(850px, 100%) !important;
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
  color: white !important;
  font-size: 22px;
  align-items: center;
}

.cartItems_container .edit_in_cart_c {
  flex-direction: column;
}

/*  */
.cartItems_container .edit_in_cart_c>div:first-child {
  background: #4d4d4d;
  margin-bottom: 4px !important;
}

.cartItems_container .edit_in_cart_c>div:last-child {
  background: var(--main-color);
}

.cartItems_container .edit_in_cart_c>div span {
  color: white !important;
}

.order_btns {
  width: min(850px, 100%) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px auto;
  flex-wrap: wrap
}

.order_btns button {
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 89px;
  border: 2.3px solid;
  transition: 0.6s ease-in-out;
  border-radius: 8px;
  cursor: pointer;
}

.order_btns .continue_o,
.order_btns .add_more_o:hover {
  color: white;
  background: var(--main-color);
}

.order_btns .add_more_o,
.order_btns .continue_o:hover {
  color: var(--main-color);
  background: transparent;
}

.showmodvonf {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  min-width: 300px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-size: 21px;
}

.showmodvonf .actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.showmodvonf .actions button {
  width: 100px;
  border-radius: 4px;
  cursor: pointer;
}

.showmodvonf .actions button:last-of-type {
  background-color: red;
  color: white;
}

.showmodvonf .actions button:first-of-type {
  background: var(--main-color) !important;
  color: white;
}

.MuiClock-pin.css-umzx0k-MuiClock-pin,
.css-d0vs79-MuiClockPointer-root,
.css-eg3pzz-MuiClockPointer-thumb,
.css-zddqwy-MuiClockNumber-root.Mui-selected {
  background: var(--main-color) !important;
}

.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root {
  background: var(--main-color) !important;
  color: white !important;
  padding: 4px !important;
  border-radius: 4px !important;
}

.showmodvonf.order_conf {
  background: var(--main-color);
  color: white;
  padding: 20px;
  width: min(451px, 100%);
}

.showmodvonf.order_conf .order_act button {
  width: 80%;
  margin: auto;
  background-color: white !important;
  color: var(--main-color) !important;
  padding: 10px 20px;
}

.showmodvonf.order_conf>span {
  width: 80%;
  text-align: center;
  margin: 20px 0;
  padding: 14px 5px;
  border: 4px dotted;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 15px;
}

button.Cancel_Order {
  width: 200px;
  padding: 12px 16px;
  margin: auto;
  background: var(--main-color);
  color: white;
  border-radius: 6px;
  font-size: 19px;
}

.orderstatus {
  display: flex;
  flex-direction: column;
}


.ev_order {
  background: transparent !important;
  color: var(--main-color) !important;
  border: 3px dotted;
}


@media(max-width:775px) {
  form {
    padding: 10px !important;
  }

  .product_card {
    width: 100% !important;
    /* height: 190px !important; */

  }

  .product_card .product_image,
  .product_card .product_image img {
    height: 100%;
    width: 200px !important;
  }

  .product_text p {
    font-size: 15px !important;
  }

  .product_price_details p {
    font-size: 15px !important;
  }

  button.addCart.rs-btn.rs-btn-default {
    width: 53px !important;
    height: 46px !important;
  }
}

@media(max-width:475px) {
  .product_card {
    width: 100% !important;
    /* height: 150px !important; */

  }

  .product_card .product_image,
  .product_card .product_image img {
    height: 100%;
    width: 170px !important;
  }

  button.addCart.rs-btn.rs-btn-default {
    width: 33px !important;
    height: 36px !important;
  }
}

@media (max-width: 600px) {
  .header_co {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    gap: 6px !important;
  }

  .purchases .purchase div:first-of-type h4 {
    font-size: 17px !important;
  }

  .changeBranch {
    padding: 4px;
    font-size: 13px !important;
  }

  a.covertToLogin img {
    width: 30px !important;
  }

  a.covertToLogin {
    font-size: 13px !important;
  }

  .rs-btn-icon.rs-btn {
    line-height: 15px;
    padding: 4px !important;
  }

  span#toggle_side {

    padding: 2px !important;

  }

  .changeBranch {
    padding: 4px 5px !important;
  }

  a.covertToLogin span {
    margin: 0 4px !important;
  }

  header {
    padding: 10px 5px !important;
  }

  .product_text p {
    font-size: 10px !important;
  }

  .edit_in_cart {
    /* flex-wrap: wrap; */
    justify-content: center !important;
    padding-bottom: 20px !important;
  }

  .changePrice {
    width: fit-content !important;
  }

  .awssld__container {
    height: 100% !important;
  }

  .package_container {
    padding: 10px !important;
  }

  .addB {
    margin: auto !important;
  }

  a.cart {
    padding: 3px 4px !important;
    font-size: 21px;
  }

  .changeBranch {
    /* padding: 4px 15px; */
    font-size: 12px;
  }

  .inputs_p label {
    font-size: 13px !important;
  }

  .text_4 {
    padding: 17px 10px !important;
  }

  .arabicContent .home_id,
  .arabicContent .searchbox,
  .arabicContent .cateoriesHome {
    padding-right: 40px !important;
  }

  h2 {
    font-size: 18px !important;
    line-height: 50px !important;
  }

  .order_btns button {

    padding: 10px 66px !important;

  }

  .cartItems_container .edit_in_cart_c>div {

    font-size: 17px !important;
  }

  .arabicContent .home_id,
  .arabicContent .searchbox,
  .arabicContent .cateoriesHome {
    padding-right: 19px !important;
  }

  .products_card {
    width: 100%;
    padding: 4px !important;
  }

  .changePrice {
    font-size: 16px !important;
  }

  .cartItems_container .edit_in_cart_c>div,
  .cartItems_container .edit_in_cart_c>div span {
    font-size: 13px !important;
  }

  #root>div.siteContent.arabicContent>div>div>div>div>div:nth-child(1)>div>div>div>div.edit_in_cart>div>span {
    margin: 0 2px !important;
  }

  .cartItems_container .product_card .changePrice {
    gap: 4px !important;
  }

  .cartItems_container .product_card .edit_in_cart {
    padding-bottom: 0 !important;
    justify-content: space-between !important;
  }

  #root>div.siteContent.arabicContent>div>div>div>div>div:nth-child(1)>div>div>div>div>div.product_details>div.product_text>span {
    width: 36px !important;
    height: 36px !important;
    margin: 10px 0;
  }

  .paymentmethod {
    width: min(580px, 100%);
    max-width: 100%;
    margin: 30px auto;
    text-align: center;
    padding: 10px;
  }

  .waytogetorder {
    width: min(500px, 100%);
    margin: 30px auto;
    max-width: 100%;
    text-align: center;
    padding: 10px;
  }

  .firt_way_title div,
  .second_way_title div span,
  .second_way_title div h5 {
    font-size: 12px !important;
    margin-right: 5px;
  }

  .firt_way_title div,
  .second_way_title div {
    flex-wrap: nowrap !important;
  }

  #root>div.siteContent.arabicContent>div>div>div>div>div:nth-child(1)>div>div>div>div>div.product_details>div.product_text>span img {
    width: 100%;
    height: 100%;
  }

  #root>div.siteContent.arabicContent>div>div>div>div>div:nth-child(1)>div>div>div>div>div.product_details>div.product_text>div>p>span {
    font-size: 14px !important;
  }

  #root>div.siteContent.arabicContent>div>div>div>div>div:nth-child(1)>div>div>div>div>div.product_details>div.product_text>div>p:nth-child(1) {
    font-size: 17px !important;
  }

  #root>div.siteContent>div>div>div>div.cartItems_container>div>div>div>div>div>div.product_image {
    width: 80% !important;
  }

  .price_ch,
  .addPrice.price_ch span,
  .addPrice.price_ch p {
    font-size: 23px !important;
  }

  #root>div.siteContent.arabicContent>div>div>div>div>div:nth-child(1)>div>div>div>div.edit_in_cart>div>span {
    font-size: 13px !important;
    /* white-space: nowrap !important; */
  }

  .text_2 h4,
  .text_2 span {
    font-size: 13px !important;
  }

  .alltimediv>div {
    width: 100% !important;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 4px;
  }

  .alltimediv {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fbcebd;
    padding: 20px 10px;
    border-radius: 8px;
    color: var(--main-color);
  }

  .cartItems_container>.product_text_c .select_c {
    font-size: 17px !important;
    padding: 4px 15px !important;
  }

  .text_2 h4,
  .text_2 span {
    font-size: 13px !important;
    white-space: nowrap;
  }

  .twxt_2 {
    padding: 20px 3px !important;
  }
}

.orderstate_img+h3 {
  color: #707070 !important;
}

.orderstate_img.active+h3 {
  color: var(--main-color) !important;
}

.disc_text{
  border: 1px solid;
  margin-top: 10px !important;
  border-radius: 4px;
}



