.pagination {
  margin: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: fit-content;
  margin-left: auto;
  padding: 10px;
  border-radius: 100px;
  background: #F3F2F7;
  flex-wrap: wrap;
}

.pagination>span {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  padding: 6px;
  transition: 0.7s ease-in-out;
  border-radius: 100px;
}

.pagination>span:hover {
  background: var(--main-color);
}

.paginationelement.active {
  background: var(--main-color);
  color: white;
}

.pending {
  width: 100%;
  height: 63vh;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 99;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pending img {
  width: 120px;
}