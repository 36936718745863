.my_addresses {
  width: 500px;
  max-width: 100%;
  margin: 20px auto;
}

.addnewloc {
  text-align: center;
  width: 100%;
  margin-top: -11px;
}

.addnewloc-btn {
  width: 380px;
  max-width: 100%;
  margin: -50px auto 0px auto;
  background-color: var(--main-color);
  height: 52px;
  border-radius: 8px;
  color: white;
}

.city_div>div {
  left: 0 !important;
}

#root>div>div.siteContent.arabicContent>div>div>div>form>div.arelocdef {
  flex-direction: row;
  width: 100%;
}

#root>div>div.siteContent.arabicContent>div>div>div>form>div.arelocdef>label {
  width: fit-content !important;
}


#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1),
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1)>div,
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1)>div>div:nth-child(2),
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1)>div>div:nth-child(2)>div:nth-child(1)>div:nth-child(1)>div>div>div:nth-child(3),
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1)>div>div:nth-child(2)>div:nth-child(1)>div:nth-child(1)>div>div>div:nth-child(2),
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1)>div>div:nth-child(2)>div:nth-child(1)>div:nth-child(1)>div>div>div:nth-child(1),
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1)>div>div:nth-child(2)>div:nth-child(1)>div:nth-child(1)>div>div,
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1)>div>div:nth-child(2)>div:nth-child(1)>div:nth-child(1)>div,
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1)>div>div:nth-child(2)>div:nth-child(1)>div:nth-child(1),
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1)>div>div:nth-child(2)>div:nth-child(1),
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1)>div>div:nth-child(2),
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1)>div,
#dialog-\:r2\:>div>div>div.rs-modal-body>div>form>div:nth-child(6)>div:nth-child(1) {
  position: relative !important;
  left: 0;
  width: 100%;
}

#dialog-\:r2\: > div > div > div.rs-modal-body > div > form > div:nth-child(6){
  width: 100% !important;
  border-radius: 5px !important;
}