.purchases {
  width: min(550px, 100%);
  max-width: 100%;
  margin: 20px auto;
}

.purchases .purchase {
  border-radius: 8px;
  filter: drop-shadow(0px 6px 10.5px rgba(0, 0, 0, 0.16));
  padding: 10px;
  background: #ffffff;
  margin-bottom: 20px;
}

.purchases .purchase div {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.purchases .purchase div div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.purchases .purchase div:first-of-type>div:last-of-type h5 {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-align: right;
  color: #a0a0a0;
}



.purchases .purchase div:first-of-type h4 {
  font-size: 27px;
  font-weight: normal;
  font-style: normal;
  text-align: right;
  color: #4d4d4d;
}

.purchases .purchase div:nth-of-type(2)>div:last-of-type {
  display: flex;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}

.purchases .purchase div:nth-of-type(2)>div:last-of-type h4 {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  color: var(--main-color);
}

.purchases .purchase div:nth-of-type(2)>div:first-of-type h5 {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-align: right;
  color: #a0a0a0;
}

.purchases .purchase div:nth-of-type(3) h4 {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-align: right;
  color: #4d4d4d;
}

.purchases .purchase div:nth-of-type(3) div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.purchases .purchase div:nth-of-type(4) button:first-of-type {
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 4px 20px;
  width: 200px;
  justify-content: center;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  background-color: transparent;
  cursor: pointer;
}

.purchases .purchase div:nth-of-type(4) button:last-of-type {
  background-color: var(--main-color);
  color: white;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 200px;
  max-width: 100%;
  gap: 30px;
  padding: 7px 10px;
}

.purchases .purchase div:nth-of-type(4) button:last-of-type img {
  width: 20px;
}

@media (max-width:565px) {
  .purchases {
    padding: 10px;
  }

  .purchases .purchase div:nth-of-type(4) button:last-of-type,
  .purchases .purchase div:nth-of-type(4) button:first-of-type {
    width: 100%;
    margin-bottom: 10px;
    justify-content: center;
  }
}

.delload .rs-loader-wrapper {
  margin-bottom: 0px !important;
}

.resource_place {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap !important;
  /* justify-content: start !important; */
}

.resource_place>div {
  max-width: 90% !important;
  flex-wrap: nowrap !important;
}

@media (max-width:565px) {
  .purchases .purchase div div {
    width: 100%;
    /* text-align: center; */
    /* justify-content: center; */
  }

  .purchases .purchase div:nth-of-type(3) div {
    flex-wrap: nowrap;
  }

  .purchases .purchase div:first-of-type h4 {
    text-align: center;
    width: fit-content;
  }

  .order_status {
    flex-wrap: nowrap !important;
  }

  .order_status>div {
    width: 50% !important;
  }

  .order_time {
    text-align: center !important;
    justify-content: center !important;
  }

  .purchases .purchase div:nth-of-type(3) div {
    justify-content: flex-end;
  }

  .resource_place {
    flex-wrap: wrap !important;
  }

  .resource_place>h4 {
    width: 100% !important;
    text-align: center;
    margin-bottom: 10px;
  }

  .purchases .purchase div:first-of-type>div:last-of-type h5 {
    width: fit-content !important;
  }
}


.showeval {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.268);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}

.showeval .evaluation_div {
  position: absolute;
  width: 400px;
  height: 360px;
  border-radius: 8px;
  filter: drop-shadow(0px 6px 10.5px rgba(0, 0, 0, 0.16));
  background: #ffffff;
  padding: 20px;
  z-index: 9999999;
  max-width: 100%;
  height: fit-content !important;
}

.evaluation_div>div:first-of-type {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 10px;
}

.evaluation_div>div:first-of-type>h4 {
  color: var(--main-color);
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  text-align: right;
}

.evaluation_div>h4 {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: right;
  color: #a0a0a0;
}

.evaluation_div .stars {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 40px;
  flex-direction: row-reverse;
}

.evaluation_div .stars>* {
  cursor: pointer;
}

.evaluation_div textarea {
  width: 100%;
  height: 104px;
  resize: none;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #bbbbbb;
  margin-bottom: 20px;
}

.evaluation_div .actions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 20px;
  flex-wrap: wrap;
}

.evaluation_div .actions>button:first-of-type {
  border-color: #969696;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 4px;
  background: #e6e6e6;
  width: 100px;
  padding: 8px;
  cursor: pointer;
  max-width: 100%;
}

.evaluation_div .actions>button:last-of-type {
  border-color: #ed4a2b;
  border-width: 0.5px;
  border-style: solid;
  width: 100px;
  color: var(--main-color);
  border-radius: 4px;
  background: #ffffff;
  padding: 8px;
  cursor: pointer;
  max-width: 100%;
}

.cancle_div {
  height: 290px !important;
}

.css-12t0dn4-MuiClockPointer-thumb {
  background: var(--main-color);
  border-color: var(--main-color) !important;
  color: white !important;
}

.infinite-scroll-component {
  overflow: visible !important;
}

.deliv_img {
  padding: 10px;
  border-radius: 50%;
  background: var(--main-color);
}

.deliverPhone {
  background: white !important;
  border: .2px solid var(--main-color);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.evaluate_person_div {
  height: auto !important;
  min-height: fit-content !important;
  top: 20px;
}

.evaluate_person {
  flex-direction: column !important;
}

.showeval .evaluation_div.evaluate_person_div {
  width: auto !important;
  width: min(550px, 100%) !important;
  bottom: 10px;
}

.evaluated {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
}

.evaluated input.rs-input {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
}

.stars.evaluate_person {
  gap: 30px;
  justify-content: flex-start;
  align-items: flex-start;
}

.stars.evaluate_person>div {
  display: flex;
  flex-direction: column;
  gap: 17px;
  width: 100%;
}

.stars.evaluate_person>div label {
  white-space: nowrap;
}

.stars.evaluate_person p {
  padding: 010px;
  background: #a9a9a933;
  border-radius: 6px;
  font-weight: 600;
}

.evaluation_div.evaluate_person_div button {
  padding: 7px 42px;
  background: white;
  border: 3px solid var(--main-color);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.delivery_icon>span {
  margin-left: auto;
  margin-right: 10px;
  font-size: 21px;
}

@media (max-width:772px) {
  .alltimediv>div img {
    display: none;
  }
}